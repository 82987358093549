/* AboutUs.module.css */

.container {
  background-color: #ffffff;
}

.heading, .text {
  color: #000000; /* A strong blue for headings */
}

