/* AboutUs.module.css */
.container {
  height: auto;
  display: flex; /* Flexbox layout */
  justify-content: center;
  align-items: center;
  padding-top: 50px; /* Space above the container */
  padding-bottom: 50px; /* Space below the container */
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.description_container {
  height: auto;
  width: 50%;

}

.stats_container {
  width: 17%;
  margin-left: 3%;
}

.heading {
  text-align: left;
  margin-bottom: 20px; /* Space below the heading */
  font-weight: 550;
  font-size: 32px;
}

.text {
  line-height: 1.6; /* Improved line spacing for readability */
  text-align: left;
  font-size: 18px;
}

.swiper {
  width: 260px;
  height: 360px;
}

.swiper_slide {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}

.small_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.swiper_slide:nth-child(1n) {
  background-color: rgb(225, 225, 225);
}

.swiper_slide:nth-child(2n) {
  background-color: rgb(207, 207, 207);
}

.regLogoImg {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 10px;
}

.icon {
  font-size: 100px;
  margin-bottom: 10px;
}

.viewProStatsButton {
  padding: 5px 10px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(45deg, #9b9b9b, #b7b7b7);
  border: none;
  cursor: pointer;
  margin-top: -10px;
  margin-bottom: -10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  display: flex;
}

.viewProStatsButton:active {
  transform: scale(0.8);
  background: linear-gradient(45deg, #808080, #9b9b9b);
}

.closeIcon {
  font-size: 30px;
  position: absolute;
  top: 35px;
  right: 2%;
  color: #ffffff;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
  z-index: 100;
}

@media screen and (max-width: 768px){
  .container {
    flex-direction: column; /* Stack the elements vertically */
    height: auto;
    padding-top: 0px; /* Space above the container */
    margin-top: 10px;
  }
  .stats_container {
    width: 80%;
    margin-bottom: 30px; /* Space below the container */
    margin-left: 0%;
  }
  .description_container {
    width: 80%;
    margin-right: 0;
    padding-top: 20px;
    margin-bottom: 30px;
  }
}