.firstPage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgb(164, 163, 163);
  transition: all 0.3s ease; 
  z-index: 51;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.firstPageProHovered {
  background-color: #e69809; /* Dark background */
  transition: all 0.3s ease; 
}

.firstPageUNSWHovered {
  background-color: #ffdc00; /* Blue background */
  transition: all 0.3s ease; 
}

.firstPageUMelbHovered {
  background-color: #000f46; /* Green background */
  transition: all 0.3s ease; 
}

.header {
  font-size: 12vmin;
  font-family: Rockwell, sans-serif;
  line-height: 1;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
  margin: 0;
  text-shadow: 1px -1px 0 #fff, -1px 1px 0 #999, -10px 10px 0 #808080;
}

.languageChangeButton {
  position: absolute;
  font-size: 16px;
  top: 10px;
  border-radius: 0%;
  right: 30px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  transition: transform 0.1s, box-shadow 0.1s;
  background-color: white
}

.languageChangeButton:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: rgb(214, 214, 214);
}

.themeSelectButton {
  color: rgb(0, 0, 0); /* Black text */
  border: none;
  width: 70vmin;
  margin-top: 15px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.ProButton {
  background-color: #e69809;
  transition: all 0.3s ease; 
}

.UNSWButton {
  background-color: #ffdc00;
  transition: all 0.3s ease; 
}

.UMelbButton {
  background-color: #000f46;
  color: white;
  transition: all 0.3s ease; 
}


@media screen and (max-width: 600px)
{
  .header {
    font-size: 13vmin;
    letter-spacing: 3px;
  }

  .languageChangeButton {
    top: 5px;
    right: 5px;
    width: 160px;
    height: 5vh;
  }

  .themeSelectButton {
    width: 80vw;
  }
  
}