.section_title {
  color:#a6192e;
}

.event_title {
  color: rgb(255, 255, 255);
}

.applyNowText {
  color: rgb(255, 255, 255);
}

.swiper {
  background-color: #a6192eaa;
}