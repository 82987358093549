.box {
  background-color: transparent;
}

.content {
  display: flex;

  padding-top: 10px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 180px;
  background-color: #171818;
  color: #CCD6F6; /* Footer copyright text color */
}

.contactBox {
  width: 50%;
}

.contactBox .contactTitle {
  color: #CCD6F6; /* Title color */
  font-size: 17px;
}

.icon {
  padding: 4px;
  width: 10px;
  height: 10px;
  color: #CCD6F6;
  font-size: 35px;
  margin: 10px;
  cursor: pointer;
}

.icon:hover {
  color: #ff3300;
}

.orgInfoBox {
  width: 50%;

}

.orgInfoBox .heart {
  color: #ff3300; /* Title color */
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    padding: 0 2rem;
  }

  .contact-info, .social-media {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .social-media {
    align-items: center;
  }
}

/*  
Code Copied from: 
https://mdbootstrap.com/snippets/jquery/mdbootstrap/4686669#css-tab-view
*/

.waves {
  position: relative;
  width: 100%;
  height: 12vh;
  margin-bottom: -7px;
  /* Fix for safari gap */
  min-height: 100px;
  max-height: 400px;
}

/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
  color: #000;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Shrinking for mobile */
@media (max-width: 768px) {
  .waves {
    height: 60px;
    min-height: 40px;
  }
  .content {
    padding-top: 10px;
    height: auto;
    padding-bottom: 40px;
    flex-direction: column-reverse;
  }
  .contactBox {
    width: 100%;
  }
  .orgInfoBox {
    width: 100%;
  }
  
}

