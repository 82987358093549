.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 25px !important;
  font-weight: bold;
  color: #000000;
}

.swiper-button-prev::after {
  margin-right: 20px;
}

.swiper-button-next::after {
  margin-left: 20px;
}