.sectionTitle {
    color: #000f46;
}



  /*
  ================================================================================================
                                    Search System Style
  ================================================================================================
  */
  
  /*
  ============================= Overview =================================
  */
.overviewButton, .filterButton {
  background-color: #000f46;
  color: #ffffff;
}

.overviewButton:hover {
  background-color: #0000c3;
}

.overviewButton:active {
  background-color: #0000b1;
}

.overviewSwiper, .overviewForm {
  background-color: #dcdcdc;
}

  
.eventNavButton {
  color: rgb(255, 255, 255);
  background-color: rgb(6, 10, 99);
}

.eventNavButton:hover {
  background-color: rgb(90, 90, 202);
}

.eventNavButton:active {
  background: rgba(0, 0, 0, 0.12);
}
  
/*
============================= Search Box ==============================
*/

.searchBox {
  background-color: #000f46;
}

.searchInput input {
  border: 2px solid #d6d6d6;
}

.searchOutline, .close {
  color: rgb(255, 255, 255);
}

.pageNavContainer {
  background-color: #213da3;
}

.searchBox, .searchResults {
  background-color: #000f46;
  color: white;
}

.searchResult:hover {
  color: #000f46;
}

.searchInput input {
  border: 2px solid #d6d6d6; /* Slightly darker border for depth */
}

/* 
========================= Filter ==========================
*/


.filterForm {
  background-color: #ffffff;  
}

/*
================================================================================================
                                  Swiper Style
================================================================================================
*/
.swiper {
  background-color: #122e91;
}

.pastEventTitle {
  color: rgb(255, 255, 255);
}

.scrollableContainer {
  border: 3px solid #cbcbcb;
}