/* Navbar.css */
.navbar,
.themeDropdownButton, 
.themeButton,
.navDropdownButton,
.navButton,
.buttons
  {
  background-color: #000f46;
  color: rgb(255, 255, 255);
}

.themeDropdown:hover .themeDropdownButton, .themeButton:hover, .navDropdown:hover .navDropdownButton {
  background-color: #000929;
}

/* triangle */
.themeDropdown::after ,  .navDropdown::after{
  border-top-color:rgb(255, 255, 255);
}

.themeDropdown.open::after {
  border-bottom-color: #ffffff;
}

/* 
=====================================================================
  Nav Button 
=====================================================================
*/
.buttons .label {
  color: #ffffff;
}

.translationButtonLaptop,
.translationButtonPhone {
  color: #ffffff;
}

.translationButtonLaptop:hover, 
.translationButtonPhone:hover
{
  color: #ffffff;
}

.buttons .label:hover ,
.tabs input:nth-child(1):checked ~ .buttons .label:nth-child(1),
.tabs input:nth-child(2):checked ~ .buttons .label:nth-child(2),
.tabs input:nth-child(3):checked ~ .buttons .label:nth-child(3),
.tabs input:nth-child(4):checked ~ .buttons .label:nth-child(4)
{
  color: #ffffff;
  filter: drop-shadow(0 0 10px #ffffff) drop-shadow(0 0 20px #ffffff);
}

.underline::before, .underline::after, .underlineChinese::before, .underlineChinese::after {
  background-color: white;
  filter: drop-shadow(0 0 10px #ffffff) 
          drop-shadow(0 0 20px #ffffff);
}