.section_title {
  color:#000f46;
}

.event_title {
  color: rgb(255, 255, 255);
}

.applyNowText {
  color: rgb(255, 255, 255);
}

.swiper {
  background-color: #000f46e9;
}