/* Navbar.css */
.navbar {
  background-color: #a6192e;
}

.themeDropdownButton, .themeButton , .navDropdownButton, .navButton  {
  background-color: #a6192e;
  color: rgb(255, 255, 255);
}

.themeDropdown:hover .themeDropdownButton, .themeButton:hover, .navDropdown:hover .navDropdownButton {
  background-color: #76232f;
}

/* triangle */
.themeDropdown::after ,  .navDropdown::after{
  border-top-color:rgb(255, 255, 255);
}

.themeDropdown.open::after {
  border-bottom-color: #ffffff;
}
