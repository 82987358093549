.chartContainer {
    position: relative;
    width: 95%;
    height: 90%;
    background-color: black;
    border-color: black;
    border-radius: 10px;
    border: 2px solid black;
    transition: all 3s ease-in-out;
  }
  
  .dropdownContainer {
    position: absolute;
    top: calc(50% + 10px + 7%);
    left: 50.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .dropdown {
    font-size: 16px;
    padding: 1px;
    width: 75%;
    color: #000000;
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
  }

  .donut .tooltip {
    z-index: 2; /* Ensure the tooltip has a higher z-index */
  }