/* SocialMediaLinks.css */
.box {
  background-color: #ffffff; /* Footer background color */
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 53%;
  display: flex;
  margin-top: 50px;
  margin-bottom: 40px;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
}

.description_container {
  width: 50%;
  height: 100%;
}

.title {
  margin-top: 0px;
  text-align: left;
  font-weight: 550;
  font-size: 32px;
  font-size: 32px;
}

.description {
  font-size: 20px;
  text-align: left;
}

.signup_container {
  height: 100%;
  width: 40%;
}

.signup_container_visible {
  background-color:rgb(244, 244, 244);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
}

.signup_container_visible:hover {
  transform:translateY(-5px);
  transition: 0.15s;
  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
}


.signup_title {
  font-size: 20px;
  font-weight: 100px;
}

.signup_price {
  font-size: 45px;
  font-weight: 100px;
}

.signup_benefit {
  font-size: 18px;
  font-weight: 100px;
}

.signup_button {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  padding: 10px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  cursor: pointer;
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  animation: animate 8s linear infinite;
  transition: 2s;
}
@keyframes animate {
  from {
    background-position: 0%;
  }
  to {
    background-position: 400%;
  }
}

.signup_container_visible:hover .signup_button {
  filter: invert();
}

@media (max-width: 768px) {
  .socialMedia {
    padding: 0px 0;
  }

  .container {
    flex-direction: column;
    align-items: center;
    width: 85%;
    height: auto;
  }

  .description_container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    height: auto;
  }

  .title {
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 40px;
    text-align: center;
  }

  .description {
    font-size: 16px;
    text-align: center
  }
  
  .signup_container {
    margin-top: 20px;
    width: 80vw;
    height: 100;
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0%;
  }

  .signup_container_visible {
    height: 100%;
    padding-bottom: 30px;

  }

  .signup_title {
    margin-top: 30px;
    font-size: 15px;
  }

  .signup_price {
    font-size: 30px;
  }
  
  .signup_benefit {
    font-size: 15px;
  }

  .signup_button {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}
