.background {
  height: auto;
  background-color: rgb(211, 211, 211);
  align-items: center;
  padding-bottom: 80px;
}

.section_title {
  font-weight: 550;
  font-size: 32px;
  text-align: center;
  padding: 55px;
}

.box {
  width: 70%;
  height: fit-content;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.25), 0 7px 14px rgba(0, 0, 0, 0.22);
  background: white;
}

.box .calander {
  aspect-ratio: 0.8 / 1;
  height: 20vw;
  margin-right: 0;
}

.box .calander .month {
  margin-top: 30px;
  font-size: 30px;
  font-weight: 600;
  width: 100%;
}

.box .calander .date {
  display: flex;
  display: flex;
  align-items: center;
  font-size: 150px;
  font-weight: 600;
  justify-content: center;
  width: 100%;
  height: 60%;
}

.box .eventContent {
  width: 100%;

  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: fit-content;
}

.box .eventContent .eventTitle {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  text-align: left;
}

.box .eventContent .eventTimeLocationContainer {
  width: 100%;
  align-items: start;
  display: flex;
  flex-direction: row;
}

.box .eventContent .eventTimeLocationContainer .eventTime {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-right: 10px;
}

.box .eventContent .eventTimeLocationContainer .eventLocation {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.box .eventContent .eventDescription {
  font-size: 18px;
  text-align: left;
}

.box .eventJoin {
  width: 60%;
  height: 100;
  padding-right: 25px;
  padding-left: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box .eventJoin .eventImg {
  margin-top: 20px;
  width: 90%;
  height: auto;
  padding: 10px; /* Space between the image and the border */
  border: 2px solid; /* Border color for the image */
  box-shadow: 0 4px 8px rgba(148, 146, 146, 0.1); /* Subtle shadow for depth */
}


.box .eventJoin .signUpButton {
  margin-top: 20px;
  width: 100%;
  font-size: 16px;
  color: #000000;
  background: rgba(187, 184, 184, 0.486);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.4), 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-weight: bolder;
}

.box .eventJoin .signUpButton:hover {
  background: rgba(187, 184, 184, 0.4);
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.4), 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

/** For mobile */
@media screen  and (max-width: 860px) {
    .box {
      flex-direction: column;
      height: auto;
      width: 90%;
    }

    .box .calander {
      aspect-ratio: 1 / 1;
      width: 90%;
      padding-left: 5%;
      padding-right: 5%;
      margin-bottom: 3px;
    }

    .box .eventContent {
      width: 90%;
      padding: 0px;
      padding-left: 5%;
      padding-right: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .box .eventContent .eventTitle {
      margin-top: 30px;
      text-align: center;
    }
    .box .eventContent .eventTimeLocationContainer {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .box .eventContent .eventTimeLocationContainer .eventTime {
      margin-bottom: 10px;
    }

    .box .eventContent .eventDescription {
      text-align: center;
      margin-bottom: 10px;
    }

    .box .eventJoin {
      width: 100%;
      height: 100;
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .box .eventJoin .eventImg {
      margin-top: 0px;
      width: 80%;
    }

    .box .eventJoin .signUpButton {
      width: 86%;
      margin-top: 30px;
      margin-bottom: 40px;
    }
    
    .star:nth-child(1) {
      translate: -50% -50%;
      scale: 0.05;
    }
    
    .signUpButton:hover .star:nth-child(1) {
      translate: -47.5% -58%;
    }
    
    .star:nth-child(2) {
      translate: -100% -50%;
      scale: 0.09;
    }
    
    .signUpButton:hover .star:nth-child(2) {
      translate: -105% -51.5%;
    }
    
    .star:nth-child(3) {
      translate: -45% -42%;
      scale: 0.075;
    }
    
    .signUpButton:hover .star:nth-child(3) {
      translate: -47.5% -36%;
    }
    
    .star:nth-child(4) {
      translate: -10% -40%;
      scale: 0.05;
    }
    
    .signUpButton:hover .star:nth-child(4) {
      translate: -5% -37%;
    }
    
    .star:nth-child(5) {
      translate: -10% -46%;
      scale: 0.04;
    }
    
    .signUpButton:hover .star:nth-child(5) {
      translate: -8% -52%;
    }


}