/* Cover.css */
.about-section {
  position: relative;
  text-align: center;
  color: white;
  padding: 19px 20px;
  height: auto;
  min-height: 73vh;
}

.background-image {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
}

.aws-text {
  position: relative;
  z-index: 1;
  margin-top: 30vh;
  font-size: 22px;
  color: rgb(255, 255, 255);
  text-shadow: 
  -1px -1px 0 #000,  
   1px -1px 0 #000,
  -1px  1px 0 #000,
   1px  1px 0 #000; /* Black text border */
}