.sectionTitle {
  color:rgb(0, 0, 0);
}


/*
================================================================================================
                                    Search System Style
================================================================================================
*/

/*
============================= Overview =================================
*/

.overviewButton {
  background-color: #d2d2d2;
}

.overviewForm {
  background-color: #ffffff;
  color: #000000;
}

.overviewSwiper {
  background-color: #ffffff;
}

.eventNavButton {
  color: rgb(0, 0, 0); /* Text color */
  background-color: #777777;
}

.eventNavButton:hover {
  background-color: #5a5a5a;
}

.eventNavButton:active {
  background:rgba(0, 0, 0, 0.12);
}

/*
============================= Search Box ==============================
*/

.pageNavContainer {
  background-color: #f0f0f0;
}

.searchBox, .searchResults {
  background-color: #d2d2d2;
}

.searchInput input {
  border: 2px solid #d6d6d6; /* Slightly darker border for depth */
}

/* 
========================= Filter ==========================
*/

/* Filter dropdown and button need to have same width height*/
.filterDropdown {
  color: #000000;
}

.filterButton {
  background-color: #d2d2d2;
  color: #000000;
}

.filterForm {
  background-color: #ffffff;
}

/*
================================================================================================
                                    Swiper Style
================================================================================================
*/
.swiper {
  background-color: #ffffff;

}

.pastEventTitle {
  color: rgb(0, 0, 0);
}

.scrollableContainer {
  border-color: #dcdada;

}