.sectionContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
}

.orgContainer {
    width: 33%;
}

.qrCode {
    width: 80%;
    height: 80%;
}

@media screen and (max-width: 768px) {
    .sectionContainer {
        flex-direction: column;
    }

    .orgContainer {
        width: 100%;
    }
    
}