.box {
  flex-direction: row-reverse;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contentBox {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row-reverse;
  display: flex;
}

.blog_brief_container {
  width: 45%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centreBox {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.sectionTitle {
  font-weight: 550;
  font-size: 32px;
  text-align:  left;
  padding-bottom: 10px;
}

.blog_brief_description_container {
  width: 100%;
  height: 80px;
}

.latest_news_container {
  width: 100%;
}

.sectionDescription {
  font-size: 18px;
  text-align: left;
}

.latest_news_title {
  font-size: 26px;
  font-weight: 600;
  text-align: left;
}

.latest_news_content {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.blog_articles_container {
  width: 45%;
  margin-right: 5%;
  height: 87vh;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

/*
================================================================================================
                                    Search System Style
================================================================================================
*/

.searchItemSystemContainer {
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
}
/*
============================= Overview =================================
*/

.overviewDropdown {
  position: relative;
  display: inline-block;
  width: 100px;
}

.overviewButton {
  width: 100%;
  height: 100%;
  border: none;
  transition: box-shadow 0.3s background-color 3s;
  color: rgb(0, 0, 0);
  cursor: pointer;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
  font-size: 15px;
}

.overviewButton:active {
  letter-spacing: 1px;
  color: hsl(0, 0%, 0%);
  transform: scale(0.90);
  transition: 100ms;
}

.overviewForm {
  position: absolute;
  z-index: 2;
}

.overviewContainer {
  height: 95%; /* Set the max height according to your needs */
  width: 100%; /* Set the width according to your needs */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px 0px; /* Add padding if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blogNavButton {
  padding: 10px;
  font-size: 14px;
  width: 90%;
  min-width: 120px; /* 中文最小宽度 */
  white-space: nowrap; /* 禁止换行 */
  font-family: Arial, sans-serif;
  text-align: left;
  margin-bottom: 5px;
  border-radius: 1px;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.blogNavButton:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); 
}

.blogNavButton:active {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12); 
}

/*
============================= Search Box ==============================
*/

/* 
  Container needs width and 2 filter buttons need to have width 
  total = swiper width
*/
.pageNavContainer {
  position: relative;
  display: flex;
  justify-content: center; /* Centers horizontally */
  height: 100%;
  width: 100%;
}

.searchBox {
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Distributes space between items */
  height: 100%;
  width: auto; /* Initial width */
  transition: 0.4s; /* Smooth transition for background color and shadow */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
}

.searchBox .searchOutline {
  padding: 9px;
}

.searchBox .close {
  padding: 6px;
  padding-right: 8px;
}

.searchBox.expanded {
  width: 100%; /* Expanded width */
}

.searchBox.collapsed {
  width: 50px; /* Collapsed width, back to initial */
}

.searchInput {
  flex-grow: 1;
}

.searchInput input {
  width: calc(100% - 8px);
  height: auto;
  font: 100 1em Arial, sans-serif;
  outline: none;
}

.searchResults {
  position: absolute;
  top: 100%; /* Position below the search box */
  width: 100%;
  max-height: 300px; /* Limit the height */
  z-index: 90; /* Ensure it appears above other elements */
}

.searchResult {
  width: auto; /* Full width */
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
  padding: 9px; /* Padding around the text */
  font-size: 1em;
  text-align: left; /* Align text to the left */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.searchMagGlass {
  padding: 1px;
  margin-right: 6px;
  margin-left: 0px;
}

.searchResult:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

/* 
========================= Filter ==========================
*/

/* Filter dropdown and button need to have same width height*/
.filterDropdown {
  position: relative;
  display: inline-block;
  width: 100px;
}

.filterButton {
  width: 100%;
  height: 100%;
  color: rgb(0, 0, 0);
  border: black;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
  transition: transform 0.3s, box-shadow 0.3s background-color 3s;
  cursor: pointer;
  font-size: 15px;
}

.filterButton:active {
  letter-spacing: 1px;
  transform: scale(0.90);
  transition: 100ms;
}

.filterForm {
  display: block;
  position: absolute;
  width: 250px;
  right: 0;
  z-index: 2;
  overflow: hidden;
}

.filterForm label {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.filterForm label input {
  margin-right: 10px;
}

.filterDisplay {
  text-align: left;
}

/*
================================================================================================
                                    Swiper Style
================================================================================================
*/
.swiper {
  width: 100%;
  height: 90.5vh;
  margin-bottom: 10px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
}

.pastBlogTitle {
  font-size: 2vh;
  text-align: center;
  margin-top: 10px;
}

.overviewNavText {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 100;
}

.scrollableContainer {
  width: 90%; /* or a fixed width */
  height: 90%; /* adjust the height as needed */
  overflow-y: auto; /* enables vertical scrolling */
  box-sizing: border-box; 
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid;
}

.pastBlogImage {
width: 100%; /* Make the image take up 100% of the container's width */
height: auto; /* Adjust the height automatically to maintain aspect ratio */
object-fit: cover; /* Cover the container while keeping the aspect ratio; it can cut off parts of the image */
}

/*
================================================================================================
                                    Media Style
================================================================================================
*/
@media (max-width: 768px) {

  .box {
    flex-direction: column;
    margin-top: 0px;
  }

  .contentBox {
    flex-direction: column;
    width: 90vw;
  }

  .blog_brief_container {
    width: 90vw;
    padding-right: 0%;
    margin-left: 0%;
    height: auto;
    margin-bottom: 20px;
  }

  .sectionTitle {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 2px;
    margin-top: 30px;
    text-align: center;
  }

  .blog_brief_description_container {
    width: 100%;
    height: auto;
  }

  .sectionDescription {
    text-align: center;
  }

  .latest_news_container {
    width: 100%;
    height: auto;
  }

  .latest_news_title {
    font-size: 24px;
  }
  
  .latest_news_content {
    font-size: 16px;
  }

  .blog_articles_container {
    width: 88vw;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
    height: 95vh;
  }

  .overviewDropdown {
    width: 20%;
  }

  .filterDropdown {
    width: 20%;
  }

  .swiper {
    width: 100%;
  }

  .scrollableContainer {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .blogTitle {
    font-size: 20px;
  }
  
  .searchInput input {
    height: 100% !important; /* 填满容器高度 */
    padding: 0 8px !important; /* 移除上下内边距 */
    box-sizing: border-box; /* 包含内边距在高度内 */
  }

  .blogNavButton {
    font-size: 13px; /* 缩小字体 */
    padding: 8px 12px; /* 调整内边距 */
    min-width: 100px; /* 移动端最小宽度 */
  }
  
  /* 筛选按钮容器调整 */
  .searchItemSystemContainer {
    flex-wrap: nowrap; /* 禁止换行 */
    gap: 5px; /* 缩小间距 */
    height: 35px; /* 固定高度 */
  }
  
  /* 按钮文字强制单行 */
  .overviewButton, .filterButton {
    white-space: nowrap;
    padding: 0 8px; /* 调整内边距 */
  }

  /* 调整搜索栏展开尺寸 */
  .searchBox.expanded {
    width: 100%;
    height: 100%; /* 填满容器高度 */
  }

  /* 缩小搜索系统容器间距 */
  .searchItemSystemContainer {
    gap: 0; /* 移除按钮间距 */
  }

  /* 调整搜索图标容器 */
  .searchOutline {
    height: 100%;
    display: flex;
    align-items: center;
  }

  /* 调整搜索结果容器边距 */
  .searchResults {
    top: 35px; /* 与搜索栏高度一致 */
  }
}