.sectionTitle {
  color:rgb(0, 0, 0);
}

.box {
  background-color: #adadad;
}


/*
============================= Overview =================================
*/

.overviewButton {
  background-color: #969797;
}

.overviewForm {
  background-color: #ffffff;
  color: #000000;
  border: #000000 1px solid;
}

.overviewSwiper {
  background-color: #ffffff;
}

.blogNavButton {
  color: rgb(0, 0, 0); /* Text color */
  background-color: #777777;
}

.blogNavButton:hover {
  background-color: #5a5a5a;
}

.blogNavButton:active {
  background:rgba(0, 0, 0, 0.12);
}

/*
============================= Search Box ==============================
*/

.pageNavContainer {
  background-color: #a9a9a9;
}

.searchBox, .searchResults {
  background-color: #939393;
}

.searchInput input {
  border: 2px solid #d6d6d6; /* Slightly darker border for depth */
}

/* 
========================= Filter ==========================
*/

/* Filter dropdown and button need to have same width height*/
.filterDropdown {
  color: #000000;
}

.filterButton {
  background-color: #969797;
}

.filterForm {
  background-color: #ffffff;
}

/*
================================================================================================
                                    Swiper Style
================================================================================================
*/
.swiper {
  background-color: #e9e9e9;
}

.pastBlogTitle {
  color: rgb(0, 0, 0);
}

.scrollableContainer {
  border-color: #e0dfdf;
}