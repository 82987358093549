/* Navbar.css */
.navbar, .buttons {
  background-color: #ffdc00;
}

.themeDropdownButton, .themeButton , .navDropdownButton, .navButton  {
  background-color: #ffdc00;
  color: rgb(0, 0, 0);
}

.themeDropdown:hover .themeDropdownButton, .themeButton:hover, .navDropdown:hover .navDropdownButton {
  background-color: #e9ca00;
}

/* triangle */
.themeDropdown::after ,  .navDropdown::after{
  border-top-color:rgb(0, 0, 0);
}

.themeDropdown.open::after {
  border-bottom-color: #000000;
}

/* 
=====================================================================
  Nav Button 
=====================================================================
*/

.buttons .label:hover ,
.tabs input:nth-child(1):checked ~ .buttons .label:nth-child(1),
.tabs input:nth-child(2):checked ~ .buttons .label:nth-child(2),
.tabs input:nth-child(3):checked ~ .buttons .label:nth-child(3),
.tabs input:nth-child(4):checked ~ .buttons .label:nth-child(4) {
  color: #000000;
  filter: drop-shadow(0 0 10px #ffffff) drop-shadow(0 0 20px #ffffff);
}

.underline::before, .underline::after {
  background-color: white;
  filter: drop-shadow(0 0 10px #ffffff) 
          drop-shadow(0 0 20px #ffffff);
}