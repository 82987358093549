.background {
  background-color: rgb(211, 211, 211);
}

.section_title {
  color:rgb(0, 0, 0);
}

.box .calander, 
.box .calander .month,
.box .eventContent,
.box .eventJoin,
.box .eventJoin .eventImg {
  background-color: rgb(255, 255, 255); 
}

.box .eventJoin .eventImg {
  border: 2px solid #8f8c8c; /* Border color for the image */
}
