/* Navbar.css */
.navbar,
.themeDropdownButton, 
.themeButton,
.navDropdownButton,
.navButton,
.buttons
  {
  background-color: hsl(39, 92%, 47%);
  color: rgb(0, 0, 0);
}


.themeDropdown:hover .themeDropdownButton, .themeButton:hover, .navDropdown:hover .navDropdownButton {
  background-color: #e79e30;
  
}

/* triangle */
.themeDropdown::after ,  .navDropdown::after, .themeDropdown.open::after{
  border-top-color:rgb(0, 0, 0);
}

/* 
=====================================================================
  Nav Button 
=====================================================================
*/


.buttons .label {
  color: #000000;
}

.translationButtonLaptop,
.translationButtonPhone {
  color: #000000;
}

.translationButtonLaptop:hover, 
.translationButtonPhone:hover
{
  color: #000000;
}

.buttons .label:hover ,
.tabs input:nth-child(1):checked ~ .buttons .label:nth-child(1),
.tabs input:nth-child(2):checked ~ .buttons .label:nth-child(2),
.tabs input:nth-child(3):checked ~ .buttons .label:nth-child(3),
.tabs input:nth-child(4):checked ~ .buttons .label:nth-child(4)
{
  color: #ffffff;
  filter: drop-shadow(0 0 10px #ffffff) drop-shadow(0 0 20px #ffffff);
}

.underline::before, .underline::after, .underlineChinese::before, .underlineChinese::after {
  background-color: white;
  filter: drop-shadow(0 0 10px #ffffff) 
          drop-shadow(0 0 20px #ffffff);
}