.box {
  flex-direction: row;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #ffffff;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contentBox {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  display: flex;
}

.event_brief_container {
  width: 45%;
  margin-right: 5%;
  margin-bottom: 10px;
}


/*
================================================================================================
                                    Section Brief Description
================================================================================================
*/

.event_brief_description_container {
  width: 100%;
  height: 20vh;
}

.sectionTitle {
  font-weight: 550;
  font-size: 32px;
  text-align:  left;
  padding-top: 70px;
  padding-bottom: 20px;
}

.sectionDescription {
  font-size: 18px;
  text-align: left;
}

.brief_img_container {
  width: 100%;
  height: 40vh;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
}

.brief_img_container img {
  width: 100%;
  height: 100%;
}

/*
================================================================================================
                                    Search System Style
================================================================================================
*/

.event_articles_container {
  width: 45%;
  margin-left: 5%;
  height: 87vh;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.searchItemSystemContainer {
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
}
/*
============================= Overview =================================
*/

.overviewDropdown {
  position: relative;
  display: inline-block;
  width: 100px;
}

.overviewButton {
  width: 100%;
  height: 100%;
  border: none;
  transition: box-shadow 0.3s background-color 3s;
  color: rgb(0, 0, 0);
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  cursor: pointer;
  font-size: 15px;
}

.overviewButton:active {
  letter-spacing: 1px;
  color: hsl(0, 0%, 0%);
  transform: scale(0.90);
  transition: 100ms;
}

.overviewForm {
  position: absolute;
  z-index: 2;
}

.overviewContainer {
  height: 95%; /* Set the max height according to your needs */
  width: 100%; /* Set the width according to your needs */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px 0px; /* Add padding if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventNavButton {
  padding: 10px;
  font-size: 14px;
  width: 90%;
  font-family: Arial, sans-serif;
  text-align: left;
  margin-bottom: 5px;
  border-radius: 1px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  border: none; /* Remove default border */
  outline: none; /* Remove focus outline */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions */
}

.eventNavButton:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); 
}

.eventNavButton:active {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12); 
}

/*
============================= Search Box ==============================
*/

/* 
  Container needs width and 2 filter buttons need to have width 
  total = swiper width
*/
.pageNavContainer {
  position: relative;
  display: flex;
  justify-content: center; /* Centers horizontally */
  height: 100%;
  width: 100%;
}

.searchBox {
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Distributes space between items */
  height: 100%;
  width: auto; /* Initial width */
  transition: 0.4s; /* Smooth transition for background color and shadow */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.searchBox .searchOutline {
  padding: 9px;
}

.searchBox .close {
  padding: 6px;
  padding-right: 8px;
}

.searchBox.expanded {
  width: 100%; /* Expanded width */
}

.searchBox.collapsed {
  width: 50px; /* Collapsed width, back to initial */
}

.searchInput {
  flex-grow: 1;
}

.searchInput input {
  width: calc(100% - 8px);
  height: auto;
  font: 100 1em Arial, sans-serif;
  outline: none;
}

.searchResults {
  position: absolute;
  top: 100%; /* Position below the search box */
  width: 100%;
  max-height: 300px; /* Limit the height */
  z-index: 90; /* Ensure it appears above other elements */
}

.searchResult {
  width: auto; /* Full width */
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
  padding: 9px; /* Padding around the text */
  font-size: 1em;
  text-align: left; /* Align text to the left */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.searchMagGlass {
  padding: 1px;
  margin-right: 6px;
  margin-left: 0px;
}

.searchResult:hover {
  background-color: #f0f0f0; /* Background color on hover */
}


/* 
========================= Filter ==========================
*/

/* Filter dropdown and button need to have same width height*/
.filterDropdown {
  position: relative;
  display: inline-block;
  width: 100px;
}

.filterButton {
  width: 100%;
  height: 100%;
  border: black;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
  transition: transform 0.3s, box-shadow 0.3s background-color 3s;
  cursor: pointer;
  font-size: 15px;
}

.filterButton:active {
  letter-spacing: 1px;
  color: hsl(0, 0%, 0%);
  transform: scale(0.90);
  transition: 100ms;
}

.filterForm {
  display: block;
  position: absolute;
  width: 250px;
  right: 0;
  z-index: 2;
  overflow: hidden;
}

.filterForm label {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.filterForm label input {
  margin-right: 10px;
}

.filterDisplay {
  text-align: left;
}

/*
================================================================================================
                                    Swiper Style
================================================================================================
*/
.swiper {
  width: 100%;
  height: 90.5vh;
  margin-bottom: 10px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.22);
}

.pastEventTitle {
  font-size: 2vh;
  text-align: center;
  margin-top: 10px;
}

.overviewNavText {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 100;
}

.scrollableContainer {
  width: 90%; /* or a fixed width */
  height: 90%; /* adjust the height as needed */
  overflow-y: auto; /* enables vertical scrolling */
  box-sizing: border-box; 
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid;
}

.pastEventImage {
  width: 100%; /* Make the image take up 100% of the container's width */
  height: auto; /* Adjust the height automatically to maintain aspect ratio */
  object-fit: cover; /* Cover the container while keeping the aspect ratio; it can cut off parts of the image */
}

/*
================================================================================================
                                    Media Style
================================================================================================
*/
@media (max-width: 768px) {
  .box {
    flex-direction: column;
    margin-top: 0px;
  }

  .contentBox {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    display: flex;
  }

  .event_brief_container {
    width: 90vw;
    padding-left: 0%;
    margin-right: 0%;
  }

  .sectionTitle {
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 2px;
    margin-top: 15px;
    text-align: center;
  }

  .event_brief_description_container {
    width: 100%;
    height: 80px;
  }

  .sectionDescription {
    text-align: center;
    font-size: 17px;
  }
  
  .brief_img_container {
    height: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .event_articles_container {
    width: 88vw;
    margin-left: auto;
    padding-right: 0%;
    height: 95vh;
  }
  
  .overviewDropdown {
    width: 20%;
  }

  .filterDropdown {
    width: 20%;
  }

  .swiper {
    width: 100%;
  }

  .scrollableContainer {
    width: auto;
    margin-left: 30px;
    margin-right: 30px;
    height: 80vh;
  }

  .blogTitle {
    font-size: 20px;
  }

  .searchInput input {
    height: 100% !important;
    padding: 0 8px !important;
    box-sizing: border-box;
  }

  .searchItemSystemContainer {
    height: 35px;
  }

  .overviewButton, .filterButton {
    height: 35px;
    white-space: nowrap;
    padding: 0 8px;
  }

  .close {
    padding: 4px;
  }

  .searchBox.expanded {
    height: 100%;
  }

  .searchOutline {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .searchResults {
    top: 35px;
  }
}