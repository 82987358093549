.sectionTitle {
  color: #000000;
}

/*
================================================================================================
                                  Search System Style
================================================================================================
*/

/*
============================= Overview =================================
*/
.overviewButton, .filterButton {
background-color: #ffdc00;
color: #000000;
}

.overviewButton:hover {
background-color: #ffe647;
}

.overviewButton:active {
background-color: #ffdc00;
}

.overviewSwiper, .overviewForm {
background-color: #ffffff;
}


.eventNavButton {
color: rgb(0, 0, 0);
background-color:#ffdc00;
}

.eventNavButton:hover {
background-color: #feef8c;
}

.eventNavButton:active {
background: rgba(0, 0, 0, 0.12);
}

/*
============================= Search Box ==============================
*/

.pageNavContainer {
background-color: #faea83;
}

.searchBox, .searchResults {
background-color: #ffdc00;
}

.searchInput input {
border: 2px solid #d6d6d6;
}

.searchOutline, .close {
color: rgb(0, 0, 0);
}

/* 
========================= Filter ==========================
*/


.filterForm {
background-color: #ffffff;  
}

/*
================================================================================================
                                Swiper Style
================================================================================================
*/
.swiper {
background-color: #f0d529;
}

.pastEventTitle {
color: rgb(0, 0, 0);
}

.scrollableContainer {
border: 3px solid #cbcbcb;
}